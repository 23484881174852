<template>
    <div class="px-0">
        <MDBNavbar expand="sm" dark bg="dark" container class="mb-0" position="sticky" style="z-index: 10">
            <MDBNavbarNav class="">
                <MDBNavbarItem class="text-white my-auto mr-auto">
                    <MDBBtn color="dark" aria-controls="#sidenavDark" @click="() => toggleSideNavMenu()">
                        <MDBIcon icon="bars" size="2x"></MDBIcon>
                    </MDBBtn>
                </MDBNavbarItem>
                <MDBNavbarItem :href="homeUrl" class="ms-3">
                    <div class="d-flex flex-column text-center text-white">
                        <MDBIcon icon="globe" size="2x"></MDBIcon>
                        Web Pública
                    </div>
                </MDBNavbarItem>
                <MDBNavbarItem :href="taquillaUrl" class="ms-3" v-if="taquillaEnable">
                    <a :href="taquillaUrl" target="_blank" class="d-flex flex-column text-center text-white">
                        <MDBIcon icon="ticket" size="2x"></MDBIcon>
                        Taquilla
                    </a>
                </MDBNavbarItem>
                <MDBNavbarItem class="ms-5 text-center my-auto">
                    <a href="" class="danger link-light">
                        <MDBIcon icon="user-circle" size="xl" color="danger"></MDBIcon>
                        <MDBBadge notification color="danger" v-if="!isGuest" pill>1</MDBBadge>
                    </a>
                    <div class="text-center">
                        <small class="text-center bg-red text-light">{{ username }}</small>
                    </div>
                </MDBNavbarItem>
                <MDBNavbarItem class="ms-5 text-center my-auto">
                    <span class="danger link-light">
                        <MDBIcon icon="bell" size="2x" color="danger" @click="toggle"></MDBIcon>
                        <MDBBadge notification color="danger" v-if="!isGuest" pill>{{ notifyLength }}</MDBBadge>
                        <div class="text-center">
                            <small class="text-center bg-red text-light">Notificaciones</small>
                        </div>
                    </span>

                    <OverlayPanel ref="op">
                        <div class="flex flex-column gap-3 w-25rem">
                            <div>
                                <span class="font-medium text-900 block mb-2">Notificaciones</span>
                                <ul class="list-none p-0 m-0 flex flex-column gap-3 mt-3" v-if="!isGuest">
                                    <li v-for="(notis, name) in notify" :key="name" class="flex align-items-center gap-2">
                                        <span class="font-medium">{{(translateName(name)) }} ({{ notis.length }})</span>
                                    
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </OverlayPanel>


                </MDBNavbarItem>

            </MDBNavbarNav>

            <MDBNavbarBrand :href="homeUrl">
                <img :src="`${imageUrl}/imagenes/logos/default/logo.png`" height="40px" />
            </MDBNavbarBrand>
        </MDBNavbar>

        <MDBSideNav v-model="sidenavDark" id="sidenavDark" color="light" dark>
            <div class="mb-0 mt-2">
                <div class="d-flex justify-content-center">
                    <h4 class=" py-4 fw-bold text-wrap text-white unique-color-dark">{{ monumentoNombre }}</h4>
                </div>
                <v-select class="custom-v-select" v-bind:class="{ 'd-none': (isSpainhnSuperAdmin || isGuest) }"
                    :clearable="false" :options="monumentosOptions" v-model="monumentoSeleccionado"
                    :reduce="(m: VueSelectOption) => m.value">
                    <template #header>
                        <span class="text-white">
                            Monumentos que administra
                        </span>
                    </template>
                </v-select>

                <div class="white px-2 pt-1">

                    <!-- <MDBSelect
                        v-if="_monumentosAutorizados.length > 1" 
                        v-model="_monumentosAutorizados" 
                        label="monumento" 
                        outline 
                        @getValue="(evt:string) => changeMonument(evt)"
                        /> -->
                </div>
                <div class="px-2 pt-1 white">
                    <MDBInput type="text" size="lg" label="Buscar" v-model="searchTag" ref="searcher" icon="search"
                        v-if="!isGuest" class="text-white" @input="(evt: Event) => filterElements(evt)" />
                </div>

                <MDBContainer v-bind:class="{ 'd-none': !(_matchingItems.length) }">
                    <MDBRow v-for="(item, index) of _matchingItems" :key="index" role="button" tag="a" :href="item.to">
                        <h5 style="color: white !important;" class="text-reset">{{ item.name }}</h5>
                    </MDBRow>
                </MDBContainer>
            </div>

            <MDBSideNavMenu class="pb-5">
                <MDBSideNavItem v-for="(item, index) of navigation" :key="index" :title="item.name"
                    :collapse="item.children ? true : false">
                    <div v-if="item.children">
                        <MDBSideNavLink v-for="(child, index2) of item.children" :key="`c-${index2}`">
                            <RouterLink :to="sanitazedRoute(child.to)" class="link-light w-100">{{ child.name }}</RouterLink>
                        </MDBSideNavLink>
                    </div>
                    <div v-else>
                        <MDBSideNavLink>
                            <RouterLink :to="item.to ? sanitazedRoute(item.to) : ''" class="ms-3 link-light w-100">{{ item.name }}</RouterLink>
                        </MDBSideNavLink>
                    </div>

                </MDBSideNavItem>
                <LogoutButton class="mt-3" />
            </MDBSideNavMenu>
        </MDBSideNav>

        <MDBContainer class="mb-0 "
            :class="{ [breadCrumbSizes[props?.breadcrumbSize ?? 'default']]: props?.breadcrumbSize, 'breadcrumbFixed': props?.fixed }"
            fluid>
            <slot name="breadcrumb">
                <div v-if="dinamicLayout">
                    <MDBBreadcrumb class="px-2 m-0">
                        <MDBBreadcrumbItem
                            v-for="(breadcrumbItem, index) in (route.meta.breadcrumb as Array<{ path: string, name: string }>)"
                            :key="index" active class="fw-bold">
                            <RouterLink :to="sanitazedRoute(breadcrumbItem.path)">{{ breadcrumbItem.name }}
                            </RouterLink>
                        </MDBBreadcrumbItem>
                    </MDBBreadcrumb>
                </div>
            </slot>
        </MDBContainer>


        <MDBContainer fluid :class="{ 'pt-5 mt-4 mt-md-0': props.fixed }" class="py-3 px-md-3"
            style="background-color: #ECEFF1;">
            <slot name="main"></slot>
        </MDBContainer>

        <MDBContainer fluid class="py-3 bg-dark">
            <MDBFooter class="d-flex p-4 bg-dark text-white">
                <div class="my-auto">
                    <img :src="`${imageUrl}/imagenes/logos/default/logo.png`" height="40px">
                </div>
                <div class="text-center my-auto ms-auto align-items-center">
                    2024 ©
                    <a class="text-reset fw-bold" :href="homeUrl">Red de Patrimonio Histórico</a>
                    <p class="m-0 p-0">{{ `v${version}` }}</p>
                </div>

                <!-- Copyright -->
            </MDBFooter>
        </MDBContainer>

    </div>
</template>

<script setup lang="ts">
import { ref, computed, onBeforeMount } from 'vue';
import {
    MDBNavbar, MDBNavbarNav, MDBNavbarBrand,
    MDBIcon, MDBNavbarItem, MDBBadge, MDBSideNav, MDBInput, MDBRow,
    MDBBtn, MDBSideNavMenu, MDBSideNavItem, MDBSideNavLink, MDBContainer, MDBFooter
}
    from 'mdb-vue-ui-kit';
import { groupBy } from 'lodash';
import LogoutButton from '@/components/LogoutButton.vue';
import { MDBBreadcrumb, MDBBreadcrumbItem } from 'mdb-vue-ui-kit';
import { useRoute, useRouter, RouterLink } from 'vue-router';
import { withDefaults } from 'vue'
import 'vue-select/dist/vue-select.css';
import OverlayPanel from 'primevue/overlaypanel';
import { version } from '../../package.json';

enum BreadcrumbSize {
    sm = 'sm',
    md = 'md',
    lg = 'lg'
}


const op = ref();

const toggle = (e = false) => {
    op.value.toggle(e);
}


const imageUrl = import.meta.env.VITE_FILES_URL;
const homeUrl = import.meta.env.VITE_HOME_URL;
const taquillaUrl = import.meta.env.VITE_TICKETING_URL;

const monumentosConTaquilla = ref(false);
const taquillaEnable = computed(() => {
    if (monumentosConTaquilla.value) {
        return true;
    } else {
        return false;
    }
})

const breadCrumbSizes = {
    'sm': 'smallClassBreadCrumbs',
    'md': 'mediumClassBreadCrumbs',
    'lg': 'largeClassBreadCrumbs',
    default: ''
}
import { useUserStore } from '@/stores/user';

const modulos = ref<string[]>([]);
const route = useRoute();
const router = useRouter();
// const modulos = ref( null );
const userStore = useUserStore();
const username = computed(() => {
    return userStore.getUsername;
});

defineExpose({
    BreadcrumbSize
})

// const notifications = ref( 0 );
const props = withDefaults(defineProps<
    {
        dinamicLayout?: boolean;
        fixed?: boolean;
        breadcrumbSize?: BreadcrumbSize;
    }
>(), {
    dinamicLayout: false
})

const sanitazedRoute = (routeString: string) => {
    const params = routeString.split("/");
    const routeDinamic = params.map(p => {
        if (p.includes(":")) {
            return route.params[p.replace(":", "")];
        }
        return p;
    }).join("/");
    return routeDinamic;
}

const sidenavDark = ref(false);
function toggleSideNavMenu() {
    sidenavDark.value = !sidenavDark.value;
}

interface modulosMenuData {
    name: string,
    to: string,
    menu: string,
    superAdminOnly?: boolean,
    modulo: string,
    orden?: number,
    tags: string[],
}

const modulosMenu = ref<modulosMenuData[]>([
    // {
    //     name: 'Hoteles',
    //     to: '/configuracion-hoteles',
    //     menu: 'Configuraciones',
    //     modulo: 'MONUMENTOS',
    //     tags: ['hoteles', 'configuracion', 'hotel beds', 'hotel']
    // },
    {
        name: "Estadisticas admin",
        to: "/admin/spainhn-statistics",
        menu: '',
        modulo: 'RESERVAS',
        superAdminOnly: true,
        tags: ['estadísticas']
    },
    {
        name: 'Recorridos autoguiados',
        to: '/audioguides/audio-guided-tours',
        menu: 'Audioguias',
        modulo: 'AUDIOGUIDES',
        tags: ['audioguias', 'audioguia', 'audios', 'recorridos', 'autoguiados']
    },
    {
        name: 'Pistas de audio',
        to: '/audioguides/audio-tracks',
        menu: 'Audioguias',
        modulo: 'AUDIOGUIDES',
        tags: ['audioguias', 'audioguia', 'audios', 'recorridos', 'pistas']
    },
    {
        name: 'Monumentos',
        to: '/',
        menu: '',
        modulo: 'FICHA_MONUMENTO',
        tags: ['monumentos', 'monumento', 'editar', 'configurar']
    },
    {
        name: 'Buscador de reservas',
        to: '/buscador-reservas',
        menu: 'Reservas',
        modulo: "RESERVAS",
        orden: 0,
        tags: ['buscar', 'reservas', 'buscador']

    },
    // {
    //     name: 'Reservas de Hoteles',
    //     to: '/reservas-hoteles',
    //     menu: 'Reservas',
    //     modulo: "RESERVAS_HOTELES",
    //     tags: ['hoteles', 'hotel', 'reservas', 'buscar']
    // },
    {
        name: 'Localizadores',
        to: '/localizadores',
        menu: 'Reservas',
        modulo: "LOCALIZADORES",
        tags: ['buscar', 'localizador', 'entradas']
    },
    {
        name: 'Informe de visitas',
        to: '/informe-visitas',
        menu: 'Reservas',
        modulo: 'INFORME_VENTAS',
        tags: ['configuraciones', 'config', 'editar', 'ficha']
    },
    {
        name: 'Gestionar cupones',
        to: '/cupones',
        menu: 'Promociones',
        modulo: 'CUPONES',
        tags: ['cupón', 'cupon', 'cupones', 'invitacion']
    },
    {
        name: 'Generar cupones',
        to: '/generar-cupones',
        menu: 'Promociones',
        modulo: 'CUPONES',
        tags: ['cupón', 'cupon', 'cupones', 'invitacion']
    },
    {
        name: "Gestionar códigos",
        to: "/codigos-promocionales",
        menu: "Promociones",
        modulo: "CUPONES",
        tags: ["cupón", 'cupon', 'codigos', 'códigos', 'promociones', 'promocionales']
    },
    {
        name: 'Generar código promocial',
        to: '/crear-codigos-promocionales',
        menu: 'Promociones',
        modulo: 'CUPONES',
        tags: ['cupón', 'cupon', 'cupones', 'invitacion']
    },
    // {
    //     name: 'Exportar cupones',
    //     to: '/exportar-cupones',
    //     menu: 'Promociones',
    //     modulo: 'CUPONES',
    //     tags: ['cupón', 'cupon', 'cupones', 'invitacion']
    // },
    // {
    //     name: 'Liquidaciones Otas',
    //     to: '/liquidaciones-otas',
    //     menu: 'Pagos y liquidación',
    //     modulo: "SEPA",
    //     tags: ['sepa', 'sepas', 'pagos', 'liquidacion', 'liquidación']

    // },
    {
        name: 'Liquidación',
        to: '/liquidaciones',
        menu: 'Pagos y liquidación',
        modulo: 'SEPA',
        tags: ['sepa', 'sepas', 'pagos', 'liquidacion', 'liquidación']
    },
    {
        name: 'Historial de Liquidaciones',
        to: '/admin/historico-liquidaciones',
        menu: 'Pagos y liquidación',
        modulo: 'SEPA',
        tags: ['sepa', 'sepas', 'pagos', 'liquidacion', 'liquidación']
    },
    {
        name: 'Ver Rutas',
        to: '/rutas',
        menu: 'Rutas',
        modulo: 'RUTAS',
        tags: ['rutas', 'ruta']
    },
    // {
    //     name: 'Ver Rutas 2',
    //     to: '/rutas2',
    //     menu: 'Rutas',
    //     modulo: 'RUTAS',
    //     tags: ['rutas', 'ruta']
    // },
    {
        name: 'Tipos de Rutas',
        to: '/tipo-rutas',
        menu: 'Rutas',
        modulo: 'TIPOS_RUTAS',
        tags: ['rutas', 'ruta', 'tipo de rutas', 'tipo rutas', 'tipo', 'tipos']
    },
    {
        name: 'Tipo de Documentos',
        to: '/tipo-documentos',
        menu: 'Documentos',
        modulo: 'TIPO_DOCUMENTO',
        tags: ['documento', 'documentos', 'tipo de documentos', 'tipo documentos', 'tipos']
    },
    {
        name: 'Ver Documentos',
        to: '/documentos',
        menu: 'Documentos',
        modulo: 'DOCUMENTO',
        tags: ['documento', 'documentos']
    },
    {
        name: 'Tipos de Servicios',
        to: '/admin/tipos-servicios',
        menu: 'Catálogos',
        modulo: 'TIPOS_RUTAS',
        tags: ['servicios', 'servicio', 'tipo de servicios', 'tipo servicio', 'tipo', 'tipos']
    },

    // {
    //     name: 'Blogs',
    //     to: '/blogs',
    //     menu: 'Documentos',
    //     modulo: 'BLOG',
    //     tags: ['blog', 'blogs']
    // },
    {
        name: 'Opciones Avanzadas',
        to: '/admin/opciones-avanzadas',
        menu: 'Configuración General',
        modulo: 'OPCIONES_AVANZADAS',
        tags: ['opciones', 'opciones avanzadas', 'avanzadas', 'configuracion', 'configuración']
    },
    {
        name: 'Roles',
        to: '/admin/roles',
        menu: 'Configuración General',
        modulo: 'ROLES',
        tags: ['rol', 'roles']
    },
    // {
    //     name: 'Calendario',
    //     to: '/calendario',
    //     menu: 'Configuración General',
    //     modulo: 'CALENDARIO',
    //     tags: ['calendario', 'calendar']
    // },
    {
        name: 'Tipo de Monumentos',
        to: '/admin/tipo-monumentos',
        menu: 'Configuración General',
        modulo: 'MONUMENTOS',
        tags: ['monumentos', 'tipo', 'tipos', 'tipos monumentos']
    },
    {
        name: 'Monumentos',
        to: '/condiciones-comerciales',
        menu: 'Condiciones Comerciales',
        modulo: 'CONDICIONES_COMERCIALES',
        tags: ['coniciones comerciales', 'condiciones', 'condición', 'comerciales', 'descuento', 'comisión', 'descuentos', 'comisiones']
    },
    {
        name: 'Mayoristas',
        to: '/condiciones-comerciales-mayoristas',
        menu: 'Condiciones Comerciales',
        modulo: 'CONDICIONES_COMERCIALES',
        tags: ['mayoristas', 'mayorista', 'condiciones', 'condiciones comerciales', 'comerciales']
    },
    {
        name: 'Monumentos-Mayoristas',
        to: '/condiciones-comerciales-monumentos-mayoristas',
        menu: 'Condiciones Comerciales',
        modulo: 'CONDICIONES_COMERCIALES',
        tags: ['coniciones comerciales mayoristas', 'condiciones', 'condición', 'comerciales', 'descuento', 'comisión', 'descuentos', 'comisiones']
    },
    {
        name: 'Promotores',
        to: '/condiciones-comerciales-promotores',
        menu: 'Condiciones Comerciales',
        modulo: 'CONDICIONES_COMERCIALES',
        tags: ['coniciones comerciales promotores', 'condiciones', 'condición', 'comerciales', 'descuento', 'comisión', 'descuentos', 'comisiones']
    },
    // {
    //     name: 'Idiomas',
    //     to: '/editar-idiomas-general',
    //     menu: 'Configuración General',
    //     modulo: 'IDIOMAS',
    //     tags: ['idioma', 'idiomas', 'lenguaje']
    // },
    {
        name: 'Usuarios',
        to: '/usuarios',
        menu: 'Usuarios y clientes',
        modulo: 'USUARIOS',
        tags: ['usuario', 'usuarios', 'user']
    },
    // {
    //     name: 'Agentes',
    //     to: '/agentes',
    //     menu: 'Usuarios y clientes',
    //     modulo: 'AGENTES',
    //     tags: ['agente', 'agentes']
    // },
    {
        name: 'Ver Mayoristas',
        to: '/admin/mayoristas',
        menu: 'OTAS y Mayoristas',
        modulo: 'MAYORISTAS',
        tags: ['mayoristas', 'mayorista']
    },  {
        name: 'Solicitudes de cancelación',
        to: '/admin/mayoristas/solicitud-de-cancelacion',
        menu: 'OTAS y Mayoristas',
        modulo: 'MAYORISTAS',
        tags: ['mayoristas', 'mayorista', 'cancelacion', 'cancelaciones', 'solicitud']
    },
    {
        name: 'Administrar departures',
        to: '/admin/tourcms-departures',
        menu: 'OTAS y Mayoristas',
        modulo: 'OTAS',
        tags: ['ota', 'otas', 'departures']
    },
    {
        name: 'Ver OTAs',
        to: '/admin/otas',
        menu: 'OTAS y Mayoristas',
        modulo: 'OTAS',
        tags: ['ota', 'otas']
    },
    {
        name: 'Clientes',
        to: '/clientes',
        menu: 'Usuarios y clientes',
        modulo: 'CLIENTES',
        tags: ['cliente', 'clientes', 'client']
    },
    {
        name: 'Módulos Generales',
        to: '/admin/modulos',
        menu: 'Configuración General',
        modulo: 'MONUMENTOS',
        tags: ['modulos', 'módulos', 'general', 'generales', 'modulos generales']
    },
    {
        name: 'Facturación',
        to: '/facturacion',
        menu: 'Facturación',
        modulo: "INFORME_FACTURACION",
        tags: ['informe', 'informes', 'informe de facturación', 'informes de facturación', 'facturación', 'facturas']
    },
    {
        name: 'Configuracion de facturas',
        to: '/admin/facturacion-admin',
        menu: 'Facturación',
        modulo: "OPCIONES_AVANZADAS",
        tags: ['facturación', 'facturas', 'configuración', 'configuracion de facturas']
    },
    // {
    //     name: 'Solicitudes de mayoristas - admin',
    //     to: '/mayoristas/solicitudes',
    //     menu: 'Solicitudes',
    //     modulo: 'SOLICITUDES_MAYORISTAS',
    //     tags: ['mayoristas', 'solicitud']
    // },
    {
        name: 'Solicitudes de mayoristas',
        to: '/solicitudes-mayoristas',
        menu: 'Solicitudes',
        modulo: 'SOLICITUDES_MAYORISTAS',
        tags: ['solicitudes de mayoristas']
    },
    {
        name: 'Distribuir a través de OTAs',
        to: '/solicitudes-otas',
        menu: 'Solicitudes',
        modulo: 'FICHA_MONUMENTO',
        tags: ['solicitudes', 'solicitudes de mayoristas', 'mayoristas', 'solicitud']
    },

    {
        name: 'Solicitudes para utilizar OTAS',
        to: '/admin/solicitudes-otas',
        menu: 'Solicitudes',
        modulo: 'OTAS',
        tags: ['solicitudes', 'solicitudes de otas', 'otas', 'solicitud', 'ota']
    },
    {
        name: 'Solicitudes de monumentos',
        to: '/solicitudes-monumentos',
        menu: 'Solicitudes',
        modulo: 'SOLICITUDES_MONUMENTOS',
        tags: ['solicitudes', 'solicitud', 'monumentos', 'monumento']
    },
    {
        name: 'OTAs',
        to: '/condiciones-comerciales-otas',
        menu: 'Condiciones Comerciales',
        modulo: 'CONDICIONES_COMERCIALES',
        tags: ['ota', 'otas', 'condiciones', 'condiciones comerciales']
    },
    // {
    //     name: 'Configurar Notificaciones',
    //     to: '/notificaciones',
    //     menu: 'Notificaciones',
    //     modulo: 'RESERVAS',
    //     tags: ['notificaciones', 'correos', 'emails']
    // },
    {
        name: 'Panel de Mayoristas',
        to: '/panel-mayoristas',
        menu: 'Panel de Mayoristas',
        modulo: 'FICHA_MONUMENTO',
        tags: ['mayoristas', 'mayoristas monumento']
    },
    {
        name: 'Datos para transferencia',
        to: '/panel-mayoristas/transferencias',
        menu: 'Panel de Mayoristas',
        modulo: 'PANEL_MAYORISTAS',
        tags: ['mayoristas', 'mayoristas transferencias', 'transferencia']
    },
    {
        name: 'Ver Promotores',
        to: '/prestadores',
        menu: 'Pack Experiencias',
        modulo: 'PRESTADORES',
        tags: ['servicios', 'administrar, prestadores']
    },
    {
        name: 'Ficha de Promotor',
        to: `/ficha-prestador/${userStore.$state.user.prestadorId}/${userStore.$state.user.prestadorNombre}`,
        menu: 'Pack Experiencias',
        modulo: 'FICHA_PRESTADOR',
        tags: ['ficha', 'ficha prestador, prestador']
    },{
        name: "Traducir",
        to: "/traducciones",
        menu: "Traducciones",
        modulo: "FICHA_MONUMENTO",
        tags: ["Traducir"],
    }

]);

interface menuOrdenData {
    "Reservas": number,
    "Promociones": number,
    "Monumentos": number,
    "Pagos y liquidación": number,
    "Facturación": number,
    "Usuarios y clientes": number,
    "Solicitudes": number,
    "Condiciones Comerciales": number,
    "Configuraciones": number,
    "Configuración General": number,
    "OTAs y Mayoristas": number,
    "Rutas": number,
    "Documentos": number,
    "Promotores": number,
    "Catálogos": number,
    "Pack Experiencias": number,
    "Traducciones": number,
    "Audioguias": number,
}

const menusOrden: menuOrdenData = {
    "Reservas": 1,
    "Promociones": 14,
    "Monumentos": 2,
    "Pagos y liquidación": 3,
    "Facturación": 4,
    "Usuarios y clientes": 5,
    "Solicitudes": 6,
    "Condiciones Comerciales": 7,
    "Configuraciones": 8,
    "Configuración General": 9,
    "OTAs y Mayoristas": 10,
    "Rutas": 11,
    "Documentos": 12,
    "Promotores": 13,
    "Catálogos": 14,
    "Traducciones": 15,
    "Pack Experiencias": 16,
    "Audioguias": 17,
};

interface navigationData {
    name: string,
    orden: number,
    children?: modulosMenuData[],
    to?: string
}

const navigation = computed(() => {
    // const modulosParaEsteUsuario = this.$store.getters.getModulos;
    const preMenu: modulosMenuData[] = modulosMenu.value.filter((mm: any) => {
        if (mm.superAdminOnly && !isSpainhnSuperAdmin.value) {
            return false;
        }
        return modulos.value.includes(mm.modulo);
        // return true;
    })

    // Agrupamos por menú y luego obtenemos los menus disponibles
    const modulosAgrupadosPorMenu = groupBy(preMenu, m => m.menu)
    const menus = Object.keys(modulosAgrupadosPorMenu);

    // Convertimos en submenús
    const subMenus: navigationData[] = menus.filter(m => m !== "").map(m => {
        const menuObj: navigationData = {
            name: m,
            orden: menusOrden[m as keyof menuOrdenData],
            children: modulosAgrupadosPorMenu[m].sort(function (a, b) {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;

            })
        }

        return menuObj;
    })

    // Obtenemos los módulos que no están asociados a un submenú
    const menusGenerales = preMenu.filter(m => m.menu === "").map(m => {
        const obj = {
            name: m.name,
            orden: menusOrden[m.name as keyof menuOrdenData],
            to: m.to
        }
        return obj;
    })
    const navigation = subMenus.concat(menusGenerales);

    navigation.sort(function (a, b) {
        return a.orden - b.orden;
    })

    // Unimos submenus y sin submenú, después ordenamos por órden alfabético
    return navigation;
})

const monumentoNombre = ref("");
// const _monumentosAutorizados = ref([]);

// function changeMonument( evt: string ){

// }

const searchTag = ref("");
const filteredItems = ref<modulosMenuData[]>([]);
function filterElements(evt: Event) {

    const { target } = evt;
    let searchWord = "";

    if (target) {
        searchWord = (target as HTMLInputElement).value
    }

    // Primero, consultamos los módulos que están asociados al rol de este usuario
    // const modulosParaEsteUsuario = this.$store.getters.getModulos;
    // Descupués, filtramos solo los menús que están autorizados para este rol
    const preMenu = modulosMenu.value.filter((mm: any) => {
        return modulos.value.includes(mm.modulo);
        // return true;
    })


    if (searchWord === null || searchWord === '') {
        filteredItems.value = [];
        return;
    }

    if (searchWord.length > 2) {
        searchWord = searchWord.toLowerCase();

        const itemsToShow = preMenu.filter(m => {
            let valid = false;

            m.tags.forEach(t => {
                t.includes(searchWord) && (valid = true);
            })

            return valid;
        });

        filteredItems.value = itemsToShow;
    }
    else {
        filteredItems.value = [];
    }
}
const _matchingItems = computed(() => {
    return filteredItems.value;
})

import meService from '@/services/me.service';
import type { VueSelectOption } from '@/models/dataTypes.model';
import type { IModulo } from '@/models/users.model';
const isSpainhnSuperAdmin = ref(true);
const monumentosOptions = ref<VueSelectOption[]>([]);
const monumentoSeleccionado = ref("");
const isGuest = ref(false);
const notify = ref<{notis: string; name: string}>({} as {notis: string; name: string})
const notifyLength = ref(0)

const dictionaryNotis: Record<string, string> = {
      refunds: "Devoluciones",
      monuments: "Monumentos",
      wholesalers: "Solicitudes de mayoristas",
      prestadores: "Promotores",
      otas: "OTAs",
      approvals: "Monumentos - OTAs"
    };

    const translateName = (key: any) => {
      return dictionaryNotis[key]
    };

onBeforeMount(async () => {
    await router.isReady();
    try {
        const defaultMonument = await meService.getDefaultMonument();

        const notifyPending = await meService.getNotificationsPending();
        notifyLength.value = notifyPending.data?.length
        notify.value = notifyPending.data?.pendingRequests
        // console.log(notifyPending);        
        // console.log('NOTIS',notify.value)

        const userData = (await meService.userModules()).data;
        isSpainhnSuperAdmin.value = userData.spainhnSuperAdmin;
        modulos.value = userData.modulos.map((m: IModulo) => m.codigo)
        // console.log( userData.modulos );
        isSpainhnSuperAdmin.value = defaultMonument.data.spainhnSuperAdmin;
        if (defaultMonument.data.spainhnSuperAdmin) {
            monumentoNombre.value = "Admin";
            monumentosOptions.value = [];
            monumentosConTaquilla.value = true;
        }
        else {
            monumentoNombre.value = defaultMonument.data.nombre;
            const authorizedMonuments = await meService.getAuthorizedMonuments();
            const _monumentosConTicketing = authorizedMonuments.data.filter((m: any) => m.ticketing);

            if (_monumentosConTicketing.length > 0) {
                monumentosConTaquilla.value = true;
            } else {
                monumentosConTaquilla.value = false;
            }
            monumentosOptions.value = authorizedMonuments.data.map((m: any) => ({ label: m.nombre, value: m._id }));
            monumentoSeleccionado.value = authorizedMonuments.data._id;
        }
    } catch (err: any) {
        try {
            const { data } = (await meService.getGuestData());
            if (data.username) {
                isGuest.value = true;

                if (route.path !== `/solicitudes-monumentos/${data.username}`) {
                    router.push(`/solicitudes-monumentos/${data.username}`);
                }
            }
        } catch (err) {
            isGuest.value = false;
        }
        finally {
            isSpainhnSuperAdmin.value = false;
            monumentosOptions.value = [];
        }
    }
})
</script>

<style scoped lang="css">
.smallClassBreadCrumbs {
    padding-top: 0;
    width: 100%;
}

.breadcrumbFixed {
    position: fixed;
    background-color: #fff;
    z-index: 1000;
}

.custom-v-select>>>.vs__dropdown-toggle {
    background-color: #333;
    /* Dark background color */
    color: #fff;
    /* White text color */
}

.list-none {
  list-style-type: none;
}
</style>